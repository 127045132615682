<template>
  <div>
    <div class="like">
      <a @click="likePostHandler(true)" v-if="!isLiked"
        ><i class="far fa-heart" style="color: #6d5a3f; cursor: pointer;"></i>
        <span
          class="pl-1 d-inline-block"
          style="min-width: 15px; color: #b2a186;"
          >{{ post.likes ? post.likes.length : "0" }}</span
        >
      </a>
      <a @click="likePostHandler(false)" v-if="isLiked"
        ><i class="fas fa-heart text-danger" style=" cursor: pointer;"></i>
        <span
          class="pl-1 d-inline-block"
          style="min-width: 15px; color: #b2a186;"
          >{{ post.likes ? post.likes.length : "0" }}</span
        >
      </a>
    </div>

    <b-modal
      id="notSignedIn"
      ref="notSignedIn"
      hide-footer
      title="Všečkanje zapisov je omogočeno samo prijavljenim uporabnikom"
    >
      <div class="pb-5" style="height: 100%;">
        <div class="my-4 text-center">
          <a
              class="btn btn-link"
              @click.prevent.stop="
              () => {
                $router.push({ name: 'SignIn' });
              }
            "
          >Prijavite se</a
          >
        </div>

        <div class="my-4 text-center">
          <a
            class="btn btn-link"
            @click.prevent.stop="
              () => {
                $router.push({ name: 'Register' });
              }
            "
            >Registrirajte se</a
          >
        </div>

        <h6 class="mt-5">
          *Vsi registrirani uporabniki prejmejo 50 % popusta na 1 vstopnico v
          Slovenski etnografski muzej.
        </h6>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { db } from "@/main";
import _ from "lodash";
import firebase from "firebase/app";

export default {
  name: "LikesSystem",
  components: {},
  props: {
    post: Object
  },
  data() {
    return {
      isLiked: false,
      isLoading: false
    };
  },
  computed: {},
  mounted() {
    const _this = this;
    let likesArr = _this.post.likes ? _this.post.likes : [];

    if (_this.$store.getters.getUser) {
      let checkIfLikeAlreadyExists = _.filter(likesArr, {
        userId: this.$store.getters.getUser.id
      });
      if (checkIfLikeAlreadyExists.length > 0) {
        _this.isLiked = true;
      }
    } else {
      _this.isLiked = false;
    }
  },
  methods: {
    likePostHandler(isLiked) {
      const _this = this;
      console.log("isLiked", isLiked);

      if (
        !_this.getUser ||
        typeof _this.getUser() === "undefined" ||
        _this.getUser() === null
      ) {
        _this.$refs["notSignedIn"].show();
        return;
      }

      _this.isLiked = isLiked;

      if (isLiked) {
        _this.addLike();
      } else {
        _this.removeLike();
      }
    },

    getUser() {
      console.log("getUser", this.$store.getters.getUser);
      return this.$store.getters.getUser;
    },

    addLike() {
      const _this = this;

      const likeRecord = {
        userId: this.$store.getters.getUser.id,
        likedAt: firebase.firestore.Timestamp.now()
      };

      const posts = db.collection("posts").doc(_this.post.id);

      return db
        .runTransaction(t => {
          return t.get(posts).then(doc => {
            // doc doesn't exist; can't update
            if (!doc.exists) return;

            // update the users array after getting it from Firestore.
            const newLikeArray = _.concat(doc.get("likes"), [likeRecord]);
            t.set(posts, { likes: newLikeArray }, { merge: true });
            _this.post.likes = newLikeArray;
          });
        })
        .catch(console.log);
    },

    removeLike() {
      const _this = this;

      const posts = db.collection("posts").doc(_this.post.id);

      return db
        .runTransaction(t => {
          return t.get(posts).then(doc => {
            // doc doesn't exist; can't update
            if (!doc.exists) return;

            // update the users array after getting it from Firestore.
            const newLikeArray = _.remove(doc.get("likes"), function(
              currentObject
            ) {
              return currentObject.userId !== _this.$store.getters.getUser.id;
            });
            t.set(posts, { likes: newLikeArray }, { merge: true });
            _this.post.likes = newLikeArray;
          });
        })
        .catch(console.log);
    }
  }
};
</script>
