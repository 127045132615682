var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"kotha-sidebar"},[_c('Search'),_c('aside',{staticClass:"widget about-me-widget text-center"},[_c('PromoSlider'),_c('div',{},[_c('ul',{staticClass:"list-inline pb-0 mb-0"},[_c('li',{staticClass:"list-inline-item"},[_c('vue-goodshare-facebook',{staticClass:"text-white rounded-circle",staticStyle:{"width":"38px","height":"38px","padding-left":"8px"},attrs:{"page_url":"https://zapisi-spomina.dobra-pot.si","has_icon":""}})],1),_c('li',{staticClass:"list-inline-item"},[_c('vue-goodshare-twitter',{staticClass:"text-white rounded-circle",staticStyle:{"width":"38px","height":"38px","padding-left":"8px"},attrs:{"page_url":"https://zapisi-spomina.dobra-pot.si","has_icon":""}})],1)])]),_c('div',{staticStyle:{"width":"1px","height":"1px","overflow":"hidden"},attrs:{"id":"paypal-button-container"}}),_c('a',{staticClass:"btn btn-success mt-3 d-block",staticStyle:{"border-color":"#69a637","background-color":"#69a637","border-radius":"20px"},on:{"click":_vm.donate}},[_vm._v("Podprite nas s PayPal donacijo")])],1),_c('aside',{staticClass:"widget widget-popular-post"},[_c('h2',{staticClass:"widget-title text-uppercase text-center"},[_vm._v(" Zvrsti in podzvrsti ")]),_c('Categories')],1),(!_vm.getUser || typeof _vm.getUser() === 'undefined' || _vm.getUser() === null)?_c('aside',{staticClass:"widget news-letter-widget"},[_vm._m(0),_c('mailchimp-subscribe',{attrs:{"url":"https://dobra-pot.us13.list-manage.com/subscribe/post-json","user-id":"358f5486109d426333c963162","list-id":"a742fc9e6d"},on:{"error":_vm.onError,"success":_vm.onSuccess},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var subscribe = ref.subscribe;
var setEmail = ref.setEmail;
var error = ref.error;
var success = ref.success;
var loading = ref.loading;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return subscribe($event)}}},[_c('input',{attrs:{"type":"email","placeholder":"vpiši email naslov"},on:{"input":function($event){return setEmail($event.target.value)}}}),_c('button',{staticClass:"text-uppercase text-center btn btn-subscribe",attrs:{"type":"submit"}},[_vm._v(" Prijava ")]),(error)?_c('div',{staticClass:"pt-2 text-danger"},[_vm._v(" Prišlo do napake."),_c('br'),_vm._v(" Morda ste s tem e-naslovom že prijavljeni. ")]):_vm._e(),(success)?_c('div',{staticClass:"pt-2 text-success"},[_vm._v(" Uspešno ste oddali zahtevek za prijavo na e-novice! ")]):_vm._e(),(loading)?_c('div',{staticClass:"pt-2 text-info"},[_c('i',{staticClass:"fa fa-spinner fa-spin"})]):_vm._e()])]}}],null,false,771983053)})],1):_vm._e(),_c('aside',{staticClass:"widget latest-post-widget"},[_c('h2',{staticClass:"widget-title text-uppercase text-center"},[_vm._v("Zadnje objave")]),(_vm.posts)?_c('ul',_vm._l((_vm.posts),function(post,index){return _c('li',{key:'post_' + index,staticClass:"media"},[_c('div',{staticClass:"media-left h-100"},[_c('a',{staticClass:"popular-img h-100",staticStyle:{"width":"103px","height":"70px","margin":"0px auto","overflow":"hidden","display":"flex","align-content":"center","align-items":"center","justify-content":"center","background-color":"white","border-radius":"14px"},on:{"click":function($event){$event.stopPropagation();return (function () {
                  _vm.$router.push({
                    name: 'Post',
                    params: { postId: post.id }
                  });
                })($event)}}},[_c('img',{staticStyle:{"width":"103px","height":"70px","object-fit":"cover"},attrs:{"src":_vm.$globalFunctions.getOptimizedImage(post.photos[0]),"alt":post.title}})])]),_c('div',{staticClass:"latest-post-content"},[_c('h2',{staticClass:"text-uppercase"},[_c('a',{on:{"click":function($event){$event.stopPropagation();return (function () {
                    _vm.$router.push({
                      name: 'Post',
                      params: { postId: post.id }
                    });
                  })($event)}}},[_vm._v(_vm._s(post.title))])]),_c('p',[_vm._v(" "+_vm._s(_vm.moment.unix(post.confirmedAt.seconds).format("DD. MMMM, YYYY"))+" ")])])])}),0):_vm._e()])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h2',{staticClass:"widget-title text-uppercase text-center"},[_vm._v(" Prijava na e-novice "),_c('small',{staticClass:"d-block"},[_vm._v("Zavoda Dobra pot")])])}]

export { render, staticRenderFns }