import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/axios";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { firestorePlugin } from "vuefire";
import firebase from "firebase/app";
import "firebase/firestore";
import Vue2Editor from "vue2-editor";
import "@/assets/styles/zapisi-spomina.scss";
import VueGoodTablePlugin from "vue-good-table";
import * as VueGoogleMaps from "vue2-google-maps";
import Moment from "moment";
import momentSL from "moment/locale/sl.js";
import VueSwal from "vue-swal";
import Meta from "vue-meta";
import InstantSearch from "vue-instantsearch";
import VueJsonToTable from "vue-json-to-table";
import VueVideoPlayer from "vue-video-player";
import browserDetect from "vue-browser-detect-plugin";
import VueGtag from "vue-gtag";
import Vuelidate from "vuelidate";
import VueAudio from "vue-audio-better";
import wb from "./registerServiceWorker";
import "video.js/dist/video-js.css";
import checkView from 'vue-check-view'

Vue.prototype.$workbox = wb;

Vue.use(
  VueVideoPlayer /* {
  options: global default options,
  events: global videojs events
} */
);

import _globalFunctions from "@/helpers/globalFunctions";

Vue.prototype.$PayPal = PayPal; // eslint-disable-line

Vue.use(firestorePlugin);

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.use(Vue2Editor);

Vue.use(VueSwal);

Vue.use(Meta);

Vue.use(browserDetect);

Vue.use(InstantSearch);

// GoodTable
Vue.use(VueGoodTablePlugin);

Vue.use(VueJsonToTable);

Vue.use(VueAudio);

// vuelidate
Vue.use(Vuelidate);

Vue.use(checkView);

Vue.use(VueGtag, {
  config: {
    id: "UA-1234567-1",
    params: {
      send_page_view: false
    }
  }
});

Vue.prototype.moment = Moment;
Vue.prototype.moment.locale("sl", momentSL);
export const moment = Vue.prototype.moment;

Vue.config.productionTip = false;

firebase.initializeApp({
  apiKey: "AIzaSyAN1H8Sr7B2F5Qth0eGfkxWEANi8-nDezA",
  authDomain: "zapisispomina.firebaseapp.com",
  projectId: "zapisispomina",
  storageBucket: "zapisispomina.appspot.com",
  messagingSenderId: "959855382154",
  appId: "1:959855382154:web:0b45c9564fa8a30e1f2773",
  measurementId: "G-E0NS1DBKZV"
});
firebase.analytics();
Vue.prototype.$analytics = firebase.analytics();
export const db = firebase.firestore();
Vue.prototype.$globalFunctions = _globalFunctions;

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDS9ON0NEo3xcbYY3Db4J_eoA8mV7_rc-o",
    libraries: "places", // This is required if you use the Autocomplete plugin
    region: 'SI',
    language: 'sl',
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  }

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  // installComponents: true,
});

let app;
firebase.auth().onAuthStateChanged(currentUser => {
  if (currentUser) {
    Vue.prototype.$analytics.setUserId(currentUser.uid);
    store.dispatch("setUser", {
      username: currentUser.email,
      displayName: currentUser.displayName
    });
  } else {
    store.dispatch("setUser", null);
  }

  if (!app) {
    app = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount("#app");
  }
});

// new Vue({
//   router,
//   store,
//   render: h => h(App)
// }).$mount("#app");
