import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import firebase from "firebase";
import store from "@/store";
import EduriWidget from "@/views/EduriWidget";

Vue.use(VueRouter);

const routes = [
  {
    path: '',
    component: () => import('@/layouts/ZapisiSpominaTemplate.vue'),
    children: [
      {
        path: "/",
        name: "Home",
        props: true,
        component: Home
      },
      {
        path: "/kategorija/:category",
        name: "HomeWithCategoryFilter",
        props: true,
        component: Home
      },
      {
        path: "/regija/:region",
        name: "HomeWithRegionFilter",
        props: true,
        component: Home
      },
      {
        path: "/avtor/:author",
        name: "HomeWithAuthorFilter",
        props: true,
        component: Home
      },
      {
        path: "/zapis/:postId",
        name: "Post",
        props: true,
        component: () => import(/* webpackChunkName: "zapis" */ "../views/Post")
      },
      {
        path: "/prijava",
        name: "SignIn",
        component: () =>
          import(/* webpackChunkName: "prijava" */ "../views/auth/SignIn")
      },
      {
        path: "/registracija",
        name: "Register",
        component: () =>
          import(/* webpackChunkName: "registracija" */ "../views/auth/Register")
      },
      {
        path: "/pozabljeno-geslo",
        name: "ForgottenPassword",
        component: () =>
          import(
            /* webpackChunkName: "registracija" */ "../views/auth/ForgottenPassword"
            )
      },
      {
        path: "/o-projektu",
        name: "About",
        component: () =>
          import(/* webpackChunkName: "o_projektu" */ "../views/static/About")
      },
      {
        path: "/pogoji-uporabe",
        name: "TermsAndConditions",
        component: () =>
          import(
            /* webpackChunkName: "pogoji_uporabe" */ "../views/static/TermsAndConditions"
            )
      },
      {
        path: "/navodila",
        name: "Instructions",
        component: () =>
          import(/* webpackChunkName: "Instructions" */ "../views/static/Instructions")
      },
      {
        path: "/vsa",
        name: "Vsa",
        component: () =>
          import(/* webpackChunkName: "Vsa" */ "../views/Vsa")
      },
      {
        path: "/app",
        name: "Protected",
        component: () =>
          import(
            /* webpackChunkName: "app_protected" */ "../views/protected/ProtectedIndex"
            ),
        redirect: "/app/moji-zapisi",
        meta: {
          requiresAuth: true
        },
        children: [
          {
            path: "/app/moji-zapisi",
            name: "MyPosts",
            component: () =>
              import(
                /* webpackChunkName: "app_protected_moji_zapisi" */ "../views/protected/MyPosts"
                ),
            meta: {
              requiresAuth: true
            }
          },
          {
            path: "/app/zapis/:postId?",
            name: "EditPost",
            component: () =>
              import(
                /* webpackChunkName: "app_protected_zapis" */ "../views/protected/EditPost"
                ),
            meta: {
              requiresAuth: true
            }
          },
          {
            path: "/app/predogled-zapisa/:postId?",
            name: "PreviewPost",
            component: () =>
              import(
                /* webpackChunkName: "app_protected_predogled_zapisa" */ "../views/Post"
                ),
            meta: {
              requiresAuth: true
            }
          },
          {
            path: "/app/moj-profil",
            name: "MyProfile",
            component: () =>
              import(
                /* webpackChunkName: "app_protected_moj_profil" */ "../views/protected/MyProfile"
                ),
            meta: {
              requiresAuth: true
            }
          }
        ]
      },
      {
        path: "/admin",
        name: "Admin",
        component: () =>
          import(/* webpackChunkName: "admin" */ "../views/admin/AdminIndex"),
        redirect: "/admin/nazorna-plosca",
        meta: {
          requiresAuth: true
        },
        children: [
          {
            path: "/admin/nazorna-plosca",
            name: "Dashboard",
            component: () =>
              import(
                /* webpackChunkName: "admin_nazorna_plosca" */ "../views/admin/Dashboard"
                ),
            meta: {
              requiresAuth: true
            }
          },
          {
            path: "/admin/zapisi-v-cakanju-na-potrditev",
            name: "SubmittedPosts",
            component: () =>
              import(
                /* webpackChunkName: "admin_users" */ "../views/admin/SubmittedPosts"
                ),
            meta: {
              requiresAuth: true
            }
          },
          {
            path: "/admin/potrjeni-zapisi",
            name: "ConfirmedPosts",
            component: () =>
              import(
                /* webpackChunkName: "admin_users" */ "../views/admin/ConfirmedPosts"
                ),
            meta: {
              requiresAuth: true
            }
          },
          {
            path: "/admin/izbrisani-zapisi",
            name: "DeletedPosts",
            component: () =>
              import(
                /* webpackChunkName: "admin_users" */ "../views/admin/DeletedPosts"
                ),
            meta: {
              requiresAuth: true
            }
          },
          {
            path: "/admin/uporabniki",
            name: "Users",
            component: () =>
              import(/* webpackChunkName: "admin_users" */ "../views/admin/Users"),
            meta: {
              requiresAuth: true
            }
          }
        ]
      }]
  },
  {
    path: '',
    component: () => import('@/layouts/EN_ZapisiSpominaTemplate'),
    children: [
      {
        path: "/en",
        name: "EN_Home",
        props: true,
        component: () => import(/* webpackChunkName: "EN_Home" */ "../views/EN_Home")
      },
      {
        path: "/en/category/:category",
        name: "EN_HomeWithCategoryFilter",
        props: true,
        component: () => import(/* webpackChunkName: "EN_Home" */ "../views/EN_Home")
      },
      {
        path: "/en/region/:region",
        name: "EN_HomeWithRegionFilter",
        props: true,
        component: () => import(/* webpackChunkName: "EN_Home" */ "../views/EN_Home")
      },
      {
        path: "/en/author/:author",
        name: "EN_HomeWithAuthorFilter",
        props: true,
        component: () => import(/* webpackChunkName: "EN_Home" */ "../views/EN_Home")
      },
      {
        path: "/en/record/:postId",
        name: "EN_Post",
        props: true,
        component: () => import(/* webpackChunkName: "EN_Post" */ "../views/EN_Post")
      },
      {
        path: "/en/signin",
        name: "EN_SignIn",
        component: () =>
            import(/* webpackChunkName: "EN_SignIn" */ "../views/auth/EN_SignIn")
      },
      {
        path: "en/register",
        name: "EN_Register",
        component: () =>
            import(/* webpackChunkName: "EN_Register" */ "../views/auth/EN_Register")
      },
      {
        path: "en/forgotten-password",
        name: "EN_ForgottenPassword",
        component: () =>
            import(
                /* webpackChunkName: "registracija" */ "../views/auth/EN_ForgottenPassword"
                )
      },
      {
        path: "/en/about-the-project",
        name: "EN_About",
        component: () =>
            import(/* webpackChunkName: "EN_About" */ "../views/static/EN_About")
      },
      {
        path: "/en/terms-of-use-and-privacy-policy",
        name: "EN_TermsAndConditions",
        component: () =>
            import(
                /* webpackChunkName: "EN_TermsAndConditions" */ "../views/static/EN_TermsAndConditions"
                )
      },
      {
        path: "/en/instructions",
        name: "EN_Instructions",
        component: () =>
            import(/* webpackChunkName: "EN_Instructions" */ "../views/static/EN_Instructions")
      },
      {
        path: "/en/app",
        name: "Protected",
        component: () =>
            import(
                /* webpackChunkName: "app_protected" */ "../views/protected/ProtectedIndex"
                ),
        redirect: "en/app/my-posts",
        meta: {
          requiresAuth: true
        },
        children: [
          {
            path: "/en/app/my-posts",
            name: "EN_MyPosts",
            component: () =>
                import(
                    /* webpackChunkName: "EN_MyPosts" */ "../views/protected/EN_MyPosts"
                    ),
            meta: {
              requiresAuth: true
            }
          },
          {
            path: "en/app/zapis/:postId?",
            name: "EN_EditPost",
            component: () =>
                import(
                    /* webpackChunkName: "EN_EditPost" */ "../views/protected/EN_EditPost"
                    ),
            meta: {
              requiresAuth: true
            }
          },
          {
            path: "en/app/post-preview/:postId?",
            name: "EN_PreviewPost",
            component: () =>
                import(
                    /* webpackChunkName: "EN_Post" */ "../views/EN_Post"
                    ),
            meta: {
              requiresAuth: true
            }
          },
          {
            path: "en/app/my-profile",
            name: "EN_MyProfile",
            component: () =>
                import(
                    /* webpackChunkName: "EN_MyProfile" */ "../views/protected/EN_MyProfile"
                    ),
            meta: {
              requiresAuth: true
            }
          }
        ]
      },
    ],
  },
  {
    path: '/e-duri-widget',
    component: () => import('@/layouts/EduriTemplate.vue'),
    children: [
      {
        path: "/",
        name: "EduriWidget",
        props: true,
        component: EduriWidget
      },
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  linkActiveClass: "open",
  routes,
  scrollBehavior(to) {
    console.log("HERE");

    if (to.hash) {
      console.log("to", to);

      return window.scrollTo({
        top: document.querySelector(to.hash).offsetTop,
        behavior: "smooth"
      });
    } else {
      return {x: 0, y: 0};
    }
  }
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  // console.log("from", from);
  // console.log("to", to);
  // const selectedLang = localStorage.getItem('zapisi_spomina_language');
  // if(selectedLang !== null) {
  //   if(selectedLang === 'EN' && !to.path.includes('/en')) {
  //     router.push({name: 'EN_' + to.name}, to.params);
  //   } else if(selectedLang === 'SI' && to.path.includes('/en')) {
  //     router.push({name: to.name.split('EN_')[1]}, to.params);
  //   }
  // }

  if (requiresAuth && !store.getters.getUser) {
    const currentUser = firebase.auth().currentUser;
    if (currentUser) {
      await store.dispatch("setUser", {
        username: currentUser.email,
        displayName: currentUser.displayName
      });
    }
  }

  if (requiresAuth) {
    if (firebase.auth().currentUser) {
      next();
    } else {
      next({name: "SignIn"});
    }
  } else {
    next();
  }
});

router.afterEach(() => {
  const preLoading = document.getElementById("loading_wrap");
  if (preLoading) {
    preLoading.style.display = "none";
  }
});
export default router;
