import Vue from "vue";
import Vuex from "vuex";
import { db } from "@/main";
import _ from "lodash";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
    config: null,
    users: null,
    categories: null,
    subcategories: null,
    tags: [],
    homeOffsetTop: null
  },
  mutations: {
    SET_USER(state, payload) {
      console.log("SET_USER payload", payload);
      Vue.set(state, "user", payload);
    },
    SET_CONFIG(state, payload) {
      console.log("SET_CONFIG payload", payload);
      Vue.set(state, "config", payload);
    },
    SET_USERS(state, payload) {
      console.log("SET_USERs payload", payload);
      Vue.set(state, "users", payload);
    },
    SET_CATEGORIES(state, payload) {
      console.log("SET_CATEGORIES payload", payload);
      Vue.set(state, "categories", payload);
    },
    SET_SUBCATEGORIES(state, payload) {
      console.log("SET_SUBCATEGORIES payload", payload);
      Vue.set(state, "subcategories", payload);
    },
    SET_TAGS(state, payload) {
      console.log("SET_TAGS payload", payload);
      Vue.set(state, "tags", payload);
    },
    SET_HOME_OFFSET_TOP(state, payload) {
      console.log("SET_HOME_OFFSET_TOP", payload);
      Vue.set(state, "homeOffsetTop", payload);
    }
  },
  actions: {
    async setUser(context, payload) {
      if (payload === null) {
        context.commit("SET_USER", null);
        return;
      }

      return db
        .collection("users")
        .where("email", "==", payload.username)
        .limit(1)
        .get()
        .then(_docs => {
          const _data = _docs.docs.map(doc => doc.data())[0];
          console.log("_data", _data);
          context.commit("SET_USER", _data);
        })
        .catch(error => {
          console.log("Error getting document:", error);
        });
    },

    async setConfig(context, payload) {
      if (payload === null) {
        context.commit("SET_CONFIG", null);
        return;
      }

      return db
        .collection("config")
        .get()
        .then(_docs => {
          const _data = _docs.docs.map(doc => doc.data())[0];
          console.log("config", _data);
          context.commit("SET_CONFIG", _data);
        })
        .catch(error => {
          console.log("Error getting document:", error);
        });
    },

    async setUsers(context, payload) {
      if (payload === null) {
        context.commit("SET_USERS", null);
        return;
      }

      return db
        .collection("users")
        .get()
        .then(_docs => {
          const _data = _docs.docs.map(doc => doc.data());
          console.log("users", _data);
          context.commit("SET_USERS", _data);
        })
        .catch(error => {
          console.log("Error getting document:", error);
        });
    },

    async setCategories(context) {
      if (context.state.categories) {
        return;
      }

      return db
        .collection("krovne_kategorije")
        .get()
        .then(_docs => {
          const _data = _docs.docs.map(doc => doc.data());
          console.log("_data", _data);
          let categories = _.sortBy(_data, function(item) {
            return item.id;
          });
          let subcategories = [];
          _.each(categories, function(item) {
            console.log("item", item);
            if (item.subcategories) {
              subcategories.push(item.subcategories);
            }
          });
          console.log("categories", categories);
          console.log("---- > subcategories", subcategories.flat());
          context.commit("SET_CATEGORIES", categories);
          context.commit("SET_SUBCATEGORIES", subcategories.flat());
        })
        .catch(error => {
          console.log("Error getting categories:", error);
        });
    },

    async setTags(context) {
      return db
        .collection("tags")
        .get()
        .then(_docs => {
          const _data = _docs.docs.map(doc => doc.data());
          let tags = [];
          _.map(_data[0].tags, function(item) {
            tags.push({ text: item });
          });
          context.commit("SET_TAGS", tags);
        })
        .catch(error => {
          console.log("Error getting categories:", error);
        });
    },
    setHomeOffsetTop(context, payload) {
      console.log("offset", payload);
      context.commit("SET_HOME_OFFSET_TOP", payload);
    }
  },
  modules: {},
  getters: {
    getUser: state => state.user,
    getConfig: state => state.config,
    getUsers: state => state.users,
    getCategories: state => state.categories,
    getSubcategories: state => state.subcategories,
    getTags: state => state.tags,
    getHomeOffsetTop: state => state.homeOffsetTop
  }
});
