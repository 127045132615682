<template>
  <router-view :key="$route.fullPath" />
</template>

<script>

export default {
  name: 'App',
  components: {

  },
  data() {
    return {

    }
  },
  mounted() {

  },
  methods: {}
}
</script>

<style>
</style>
