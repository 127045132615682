var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.user)?_c('div',[(_vm.location === 'Home')?_c('div',[_c('div',{staticClass:"rounded-circle text-center overflow-hidden mr-2",staticStyle:{"width":"36px","height":"36px","display":"flex","align-content":"center","align-items":"center","text-align":"center","float":"left"}},[_c('a',{on:{"click":function () {
              _vm.$store.dispatch('setHomeOffsetTop', 0).then(function () {
                _vm.$router
                  .push({
                    name: 'HomeWithAuthorFilter',
                    params: {
                      author: _vm.user.displayName
                    }
                  })
                  .catch(function () {});
              });
            }}},[(_vm.user.profileImg)?_c('b-img',{staticStyle:{"width":"36px","height":"36px","object-fit":"cover"},attrs:{"src":_vm.user.profileImg,"loading":"lazy"}}):_vm._e(),(!_vm.user.profileImg)?_c('b-img',{staticStyle:{"width":"36px","height":"36px","object-fit":"cover"},attrs:{"src":require('@/assets/images/user-krogec.svg')}}):_vm._e()],1)]),_c('span',[_c('a',{staticClass:"userDisplayName",staticStyle:{"color":"#6d5a3f","font-size":"11px","opacity":"0.8","font-weight":"700","margin-top":"10px","display":"inline-block"},on:{"click":function () {
              _vm.$store.dispatch('setHomeOffsetTop', 0).then(function () {
                _vm.$router
                  .push({
                    name: 'HomeWithAuthorFilter',
                    params: {
                      author: _vm.user.displayName
                    }
                  })
                  .catch(function () {});
              });
            }}},[_vm._v(_vm._s(_vm.user.displayName))])])]):_vm._e(),(_vm.location !== 'Home')?_c('div',{staticClass:"top-comment"},[_c('div',{staticClass:"rounded-circle text-center overflow-hidden mr-4",staticStyle:{"width":"80px","height":"80px","display":"flex","align-content":"center","align-items":"center","text-align":"center","float":"left"}},[(_vm.user.profileImg)?_c('b-img',{staticClass:"mr-0",staticStyle:{"width":"80px","height":"80px","object-fit":"cover"},attrs:{"src":_vm.user.profileImg,"alt":_vm.user.displayName,"loading":"lazy"}}):_vm._e(),(!_vm.user.profileImg)?_c('b-img',{staticClass:"mr-0",staticStyle:{"width":"80px","height":"80px","object-fit":"cover"},attrs:{"src":require('@/assets/images/user-krogec.svg'),"alt":_vm.user.displayName}}):_vm._e()],1),_c('h4',[_c('a',{on:{"click":function () {
              _vm.$store.dispatch('setHomeOffsetTop', 0).then(function () {
                _vm.$router
                  .push({
                    name: 'HomeWithAuthorFilter',
                    params: {
                      author: _vm.user.displayName
                    }
                  })
                  .catch(function () {});
              });
            }}},[_vm._v(_vm._s(_vm.user.displayName)+" ")])]),_c('p',[_vm._v(" "+_vm._s(_vm.user.bio)+" ")])]):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }