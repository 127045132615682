<template>
  <div>
    <b-list-group>
      <b-list-group-item
        class="py-1"
        v-for="(category, index) in categories"
        :key="'category_' + index"
      >
        <span
          v-b-toggle="'collapse-' + index"
          class="bv-no-focus-ring"
          style="font-size: 0.85rem;"
        >
          <span
            class="when-closed float-left pr-1"
            style="margin-left: -5px; margin-top: 3px;"
            ><i class="fas fa-caret-right"></i
          ></span>
          <span
            class="when-open float-left pr-1"
            style="margin-left: -5px; margin-top: 2px;"
            ><i class="fas fa-caret-down"></i
          ></span>
          {{ category.name }}</span
        >
        <a
          class="btn btn-sm btn-link p-0 float-right"
          v-b-tooltip.hover
          :title="'Filtriraj vsebine po zvrsti ' + category.name"
          @click="
            () => {
              $root.$emit('bv::hide::modal', 'categoriesModal');

              $router
                .push({
                  name: 'HomeWithCategoryFilter',
                  params: {
                    category: $globalFunctions.getCategoryById(category.id)
                      .alias
                  }
                })
                .catch(() => {});
            }
          "
          ><img :src="require('@/assets/images/filter.svg')"
        /></a>

        <b-collapse :id="'collapse-' + index" class="mt-2">
          <div
            v-for="subcategory in subcategories"
            :key="subcategory.id"
            class="pl-2"
          >
            <span
              class="d-inline-block pb-1"
              v-if="subcategory.id.substring(0, 1) == index + 1"
              >{{ subcategory.name }}</span
            >
          </div>
        </b-collapse>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "Categories",
  components: {},
  props: {},
  data() {
    return {
      categories: null,
      subcategories: null
    };
  },
  computed: {},
  mounted() {
    const _this = this;
    let p1 = _this.$store.dispatch("setCategories");

    Promise.all([p1]).then(() => {
      _this.categories = _this.$store.getters.getCategories;
      _this.subcategories = _this.$store.getters.getSubcategories;
    });
  },
  methods: {
    transformItems(items) {
      return items;
    },

    filterSearchResults(items) {
      let confirmedPosts = [];
      _.map(items, function(val) {
        if (val.confirmed) {
          confirmedPosts.push(val);
        }
      });

      return confirmedPosts;
    }
  }
};
</script>
