import store from "../store/index";
import _ from "lodash";
import {Dropbox} from "dropbox";
import getUserCountry from "js-user-country";

export default {
    isPWA() {
        return window && window.matchMedia("(display-mode: standalone)").matches;
    },
    getCategoryById(categoryId) {
        let category = _.filter(store.getters.getCategories, {
            id: categoryId
        })[0];

        return category ? category : categoryId;
    },
    getCategoryByAlias(categoryAlias) {
        let category = _.filter(store.getters.getCategories, {
            alias: categoryAlias
        })[0];

        return category ? category : categoryAlias;
    },
    getSubcategoryById(subcategoryId) {
        let subcategory = _.filter(store.getters.getSubcategories, {
            id: subcategoryId
        })[0];

        return subcategory ? subcategory : subcategoryId;
    },
    getUserById(userId) {
        let user = _.filter(store.getters.getUsers, {
            id: userId
        })[0];

        return user ? user : userId;
    },
    getTranslatedRegion(regionName) {
        let translatedRegionName = regionName;
        switch (regionName) {
            case 'pomurska regija': {
                translatedRegionName = 'Mura region';
                break;
            }
            case 'podravska regija': {
                translatedRegionName = 'Drava region';
                break;
            }
            case 'koroška regija': {
                translatedRegionName = 'Carinthia region';
                break;
            }
            case 'savinjska regija': {
                translatedRegionName = 'Savinja region';
                break;
            }
            case 'zasavska regija': {
                translatedRegionName = 'Central Sava region';
                break;
            }
            case 'posavska regija': {
                translatedRegionName = 'Lower Sava region';
                break;
            }
            case 'jugovzhodna Slovenja': {
                translatedRegionName = 'Southeast Slovenia';
                break;
            }
            case  'osrednjeslovenska regija': {
                translatedRegionName = 'Central Slovenia';
                break;
            }
            case 'gorenjska regija': {
                translatedRegionName = 'Upper Carniola region';
                break;
            }
            case 'primorsko-notranjska regija': {
                translatedRegionName = 'Littoral–Inner Carniola';
                break;
            }
            case 'goriška regija': {
                translatedRegionName = 'Gorizia region';
                break;
            }
            case 'obalno-kraška regija': {
                translatedRegionName = 'Coastal–Karst region';
                break;
            }
            case 'celotno območje Slovenije': {
                translatedRegionName = 'The whole territory of Slovenia';
                break;
            }
            case 'tujina': {
                translatedRegionName = 'abroad';
                break;
            }
        }
        return translatedRegionName.toUpperCase();
    },
    getUserByDisplayName(displayName) {
        let user = _.filter(store.getters.getUsers, {
            displayName: displayName
        })[0];

        return user ? user : displayName;
    },
    getOptimizedImage(imageUrl) {
        if (!imageUrl) return null;

        let smallerImageUrl = imageUrl
            .replace("750x", "400x")
            .replace("x470", "x400");
        return smallerImageUrl;
    },
    getLargeImage(imageUrl) {
        if (!imageUrl) return null;

        let largerImageUrl = imageUrl
            .replace("750x", "1280x")
            .replace("x470", "x720");
        return largerImageUrl;
    },
    async getDropboxThumbnail(fileName) {
        // const _this = this;
        let ACCESS_TOKEN =
            "gDiID073o7UAAAAAAAAAAbqNtrvHu6shGELzZkkG2K2saiLG799bzaPJgGwZ-rOE";
        let dbx = new Dropbox({accessToken: ACCESS_TOKEN});

        const thumbnail = await dbx.filesGetThumbnail({
            path: fileName,
            size: "w640h480"
        });

        if (thumbnail.result.fileBlob) {
            var reader = new FileReader();
            reader.readAsDataURL(thumbnail.result.fileBlob);

            return new Promise(resolve => {
                reader.onloadend = function () {
                    var base64data = reader.result.replace(
                        "data:application/octet-stream;base64,",
                        "data:image/jpeg;base64,"
                    );
                    resolve(base64data);
                };
            });
        }
    },
    async detectUserLocation() {
        console.log("getUserCountry().id", getUserCountry().id);
        return getUserCountry().id === 'SI' ? getUserCountry().id : 'EN';
    }
};
