<template>
  <div>
    <div v-if="user">
      <div v-if="location === 'Home'">
        <div
          class="rounded-circle text-center overflow-hidden mr-2"
          style="width: 36px; height: 36px; display: flex; align-content: center; align-items: center; text-align: center; float: left;"
        >
          <a
            @click="
              () => {
                $store.dispatch('setHomeOffsetTop', 0).then(() => {
                  $router
                    .push({
                      name: 'HomeWithAuthorFilter',
                      params: {
                        author: user.displayName
                      }
                    })
                    .catch(() => {});
                });
              }
            "
          >
            <b-img
              v-if="user.profileImg"
              :src="user.profileImg"
              style="width: 36px; height: 36px; object-fit: cover;"
              loading="lazy"
            />
            <b-img
              v-if="!user.profileImg"
              :src="require('@/assets/images/user-krogec.svg')"
              style="width: 36px; height: 36px; object-fit: cover;"
            />
          </a>
        </div>

        <span>
          <a
            @click="
              () => {
                $store.dispatch('setHomeOffsetTop', 0).then(() => {
                  $router
                    .push({
                      name: 'HomeWithAuthorFilter',
                      params: {
                        author: user.displayName
                      }
                    })
                    .catch(() => {});
                });
              }
            "
            class="userDisplayName"
            style="color: #6d5a3f; font-size: 11px; opacity: 0.8; font-weight: 700; margin-top: 10px; display: inline-block;"
            >{{ user.displayName }}</a
          >
        </span>
      </div>

      <div v-if="location !== 'Home'" class="top-comment">
        <div
          class="rounded-circle text-center overflow-hidden mr-4"
          style="width: 80px; height: 80px;  display: flex; align-content: center; align-items: center; text-align: center; float: left;"
        >
          <b-img
            class="mr-0"
            v-if="user.profileImg"
            :src="user.profileImg"
            :alt="user.displayName"
            style="width: 80px; height: 80px; object-fit: cover;"
            loading="lazy"
          />
          <b-img
            class="mr-0"
            v-if="!user.profileImg"
            :src="require('@/assets/images/user-krogec.svg')"
            :alt="user.displayName"
            style="width: 80px; height: 80px; object-fit: cover;"
          />
        </div>

        <!--          <img src="assets/images/comment.jpg" class="pull-left img-circle" alt="">-->
        <h4>
          <a
            @click="
              () => {
                $store.dispatch('setHomeOffsetTop', 0).then(() => {
                  $router
                    .push({
                      name: 'HomeWithAuthorFilter',
                      params: {
                        author: user.displayName
                      }
                    })
                    .catch(() => {});
                });
              }
            "
            >{{ user.displayName }}
          </a>
        </h4>
        <p>
          {{ user.bio }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserAvatarWidget",
  components: {},
  props: {
    userId: String,
    location: String
  },
  data() {
    return {
      user: {
        profileImg: null,
        displayName: null,
        bio: null
      }
    };
  },
  mounted() {
    const _this = this;
    _this.user = _this.$globalFunctions.getUserById(_this.userId);
  },
  methods: {}
};
</script>
