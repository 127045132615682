<template>
  <section
      class="elementor-section elementor-top-section elementor-element elementor-element-5529476 elementor-section-stretched elementor-section-content-top elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static"
      data-id="5529476" data-element_type="section"
      data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;,&quot;background_background&quot;:&quot;classic&quot;}"
      style="background: #F2F6F7;">
    <div class="elementor-container elementor-column-gap-extended">
      <div class="elementor-row" v-if="posts && posts.length > 0">
        <div
            class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-17bee0d sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
            data-id="17bee0d" data-element_type="column">
          <div class="elementor-column-wrap elementor-element-populated">
            <div class="elementor-widget-wrap">
              <div
                  class="elementor-element elementor-element-dbeaed3 sc_height_medium sc_fly_static elementor-widget elementor-widget-spacer"
                  data-id="dbeaed3" data-element_type="widget" data-widget_type="spacer.default">
                <div class="elementor-widget-container">
                  <div class="elementor-spacer">
                    <div class="elementor-spacer-inner"></div>
                  </div>
                </div>
              </div>
              <div
                  class="elementor-element elementor-element-3774193 sc_fly_static elementor-widget elementor-widget-shortcode"
                  data-id="3774193" data-element_type="widget" data-widget_type="shortcode.default">
                <div class="elementor-widget-container">
                  <div class="elementor-shortcode">
                    <div id="ld-course-list-content-269a755cd347caa4203a0e47b9d5d72d" class="ld-course-list-content"
                         data-shortcode-atts="{&quot;num&quot;:&quot;9&quot;,&quot;col&quot;:&quot;3&quot;,&quot;course_categoryselector&quot;:&quot;false&quot;,&quot;show_content&quot;:&quot;true&quot;,&quot;progress_bar&quot;:&quot;true&quot;}">
                      <div class="ld-course-list-items row">


                        <!-- LearnDash Course Grid v1 -->
                        <div class="ld_course_grid col-sm-8 col-md-4" v-for="post in posts"
                             :key="'post_' + post.id">
                          <article id="post-4753"
                                   class=" learndash-not-available learndash-incomplete thumbnail course post-4753 sfwd-courses type-sfwd-courses status-publish has-post-thumbnail hentry">
                            <div class="ld_course_grid_price price_¤">
                              {{getCategory(post.categoryId)}}
                              </div>
                            <a :href="'https://zapisi-spomina.dobra-pot.si/zapis/' + post.id" rel="bookmark" target="_blank">
                              <img loading="lazy" width="1200" height="628"
                                   :src="post.photos[0]"
                                   class="attachment-course-thumb size-course-thumb wp-post-image" alt=""
                                   style="height: 300px; object-fit: cover"
                                   sizes="(max-width: 1200px) 100vw, 1200px"> </a>

                            <div class="caption" style="background: #ffffff">
                              <h3 class="entry-title">{{post.title}}</h3>
                              <div class="entry-content">{{post.summary}}</div>
                              <div class="ld_course_grid_button"><a class="btn btn-primary" role="button"
                                                                    :href="'https://zapisi-spomina.dobra-pot.si/zapis/' + post.id"
                                                                    rel="bookmark" target="_blank">Več</a></div>
                            </div><!-- .entry-header -->
                          </article><!-- #post-## -->
                        </div><!-- .ld_course_grid -->


                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                  class="elementor-element elementor-element-dbeaed3 sc_height_medium sc_fly_static elementor-widget elementor-widget-spacer"
                  data-id="dbeaed3" data-element_type="widget" data-widget_type="spacer.default">
                <div class="elementor-widget-container">
                  <div class="elementor-spacer">
                    <div class="elementor-spacer-inner"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import {db} from "@/main";


export default {
  name: "EduriWidget",
  components: {},
  data() {
    return {
      posts: [],
      heroPost: null,
      config: null,
      categories: null,
      subcategories: null,
      isLoading: true,
      isLoadingOverlay: false,
      query: null,

      fabBgColor: "#69a637",
      fabPosition: "bottom-right",
      fabActions: [
        {
          name: "newPost",
          icon: "edit",
          tooltip: "Nov zapis"
        },
        {
          name: "myPosts",
          icon: "list",
          tooltip: "Moji zapisi"
        }
      ]
    };
  },
  metaInfo() {
    return {
      title: "Zapisi spomina",
      titleTemplate: null
    };
  },
  computed: {},
  mounted() {
    const _this = this;
    let p1 = _this.$store.dispatch("setConfig");
    let p2 = _this.$store.dispatch("setCategories");

    Promise.all([p1, p2]).then(() => {
      console.log("categories", _this.$store.getters.getCategories);
      _this.config = _this.$store.getters.getConfig;
      _this.categories = _this.$store.getters.getCategories;
      console.log("config", _this.config);

      _this.retrievePosts();
    });
  },
  methods: {
    async retrievePosts() {
      const _this = this;

      _this.isLoading = true;

      let query = db.collection("posts");
      query = query.where("id", "in", _this.config.eDuriSelectedPosts);

      await query
          .orderBy("confirmedAt", "desc")
          .get()
          .then(function (_docs) {
            let posts = _docs.docs.map(doc => doc.data());
            _this.posts = posts;
            // console.log("posts", posts);

            setTimeout(() => {
              _this.isLoading = false;
            }, 10);
          })
          .catch(error => {
            console.log("Error getting categories:", error);
            _this.isLoading = false;
          });
    },

    getCategory(categoryId) {
      const _this = this;
      let category = _this.$globalFunctions.getCategoryById(categoryId);
      return category.name
    }
  }
};
</script>
